.phone_container {
    position: fixed;
    bottom: 20px; 
    left: 20px; 
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

  }
  .phone_img_container{
    width: 100px; 
    height:  100px;
    background-color: var(--color-blue-elements);
    border-radius: 50%;       
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  }
  .phone_img_container img {
    width: 70%;  
    height: 70%;
    animation: pulse 5s infinite; /* Анимация длится 5 секунд и повторяется бесконечно */
  }
  
  @keyframes pulse {
    0% {
      transform: scale(0.7); /* Начальный размер */
    }
    50% {
      transform: scale(1.3); /* Увеличение до 120% */
    }
    100% {
      transform: scale(0.7); /* Возвращение к исходному размеру */
    }
  }
  
  

  .phone_text_container{
    border: 1px solid;
    border-radius: 15px;
    padding: 10px;
    background: white;
    box-shadow: rgb(153, 153, 153) 0px 0px 5px 3px;
    border: 1px solid;
    border-radius: 15px;
    padding: 10px;
    background: #ffffff;
  }
  .phone_text{
    font-size: 20px;
    font-weight: 700;
  }


  @media (max-width: 500px) {
    .phone_img_container{
      width: 80px; 
      height:  80px;
  
    }
    .phone_text{
      font-size: 16px;
    }
  }