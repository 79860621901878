.advantages_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--color-gray-elements);
    padding: 50px 0;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
  
  .advanteges-title {
    color: rgb(255, 255, 255);
    padding: 0 0 50px 0;
  }
  
  .advanteges-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    max-width: 1200px;
  }
  
  .advanteges-item {
    color: black;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    width: calc(50% - 20px);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: 25px 25px;
    align-items: center;
    gap: 20px;
    height: 140px;
  }
.advanteges-item_title{
  margin-bottom: 20px;
  color: var(--color-blue-elements);
}

  .svg-advanteges{
    width: 60px;
    transition: 0.5s;
  }

  .svg-clock,
  .svg-materials,
  .svg-tehnology{
    fill: #f9f9f9;
  }
  .svg-tehnology{
    fill: black;
  }

  .advanteges-item:hover .svg-builder,
  .advanteges-item:hover .svg-shield,
  .advanteges-item:hover .svg-clock,
  .advanteges-item:hover .svg-materials,
  .advanteges-item:hover .svg-handshake,
  .advanteges-item:hover .svg-tehnology{
    filter: brightness(0.7); /* Затемнение изображения */
    fill: var(--color-blue-elements);
  }
  .advanteges-item_text-container{
    width: 450px;
  }

  @media (max-width: 500px) {
    .advanteges-item_title{
font-size: 15px;
    }
    .advanteges-item_text{
      font-size: 12px;
    }
    .advanteges-list{
      flex-direction: column;
      align-items: center;
    }
    .advanteges-item{
      width: 90vw;
    }
  }