
.aboutCompany_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 50px 0;
    background-color:var(--color-gray-elements);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
  
  .aboutCompany-title {
    font-size: 2rem;
    color: var(--color-blue-elements);;
    padding: 0 0 50px 0;
  }
  
  .aboutCompany_img-container {
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: center; 
    align-items: center;
    max-width: 1200px; 
    flex-wrap: wrap;
    
  }
  
  .aboutCompany-text {
    max-width: 585px; 
    margin-bottom: 20px;
    color: rgb(255, 255, 255);
    font-size: 16px;
    line-height: 1.6; /* Интерлиньяж для улучшения читаемости */
    text-align: justify; /* Выравнивание текста по ширине */
    height: 350px;
  }
  

  .aboutCompany-img {
    width: 400px;
    height: auto;
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }

  .ab-cont{
    display: flex;
    flex-direction: row;
    width: 1200px;
    justify-content: space-between;
  }

  @media (max-width: 768px) {
    .aboutCompany-img {
padding: 0 20px;
    }
    .ab-cont{
      flex-direction: column;
      align-items: center;
      flex-direction: column;
      width: 90vw;
    }
    .aboutCompany-text{
      height: auto;
    }
  }