:root {
  --color-blue-elements: rgb(77, 128, 255); /* Задайте нужный цвет */
  --color-gray-elements: #252525e8;
  --font-size-default: 16px;
  --padding-default: 10px;
}

::-webkit-scrollbar {
  width: 20px; /* ширина вертикального скроллбара */
}
::-webkit-scrollbar-track {
  background:#252525e8;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(77, 128, 255); /* цвет ползунка */
  border: 3px solid #828282; /* отступ внутри ползунка */
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(10, 79, 255);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family:  "Open Sans", sans-serif;
}
body {
  -webkit-text-size-adjust: 100%;
}

.list{
  list-style: none;
}
.link{
  text-decoration: none;
}
.btn{
  cursor: pointer;
}

.app .container {
  width: 1000px;
  padding: 0 20px;
  margin: 0 auto;
}


.about {
  position: relative;
  text-align: center;
  font-weight: bold;
    color: white;
}

.about__title {
  font-size: 50px;
  margin-bottom: 30px;
}

.about__description p {
  margin: 20px 0;
  font-size: 24px;
}
.about__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  filter: brightness(0.3);
}

.portfolio {
  padding: 40px 0;
}

.portfolio .container {
  display: flex;
  flex-wrap: wrap;
}

.portfolio-item {
  box-sizing: border-box;
  flex-basis: 33.33%;
  padding: 10px;
  text-decoration: none;
  color: inherit;
}

.portfolio-item {
  display: block;
}
.portfolio-item img {
  max-width: 100%;
}

.portfolio-item__title {
  margin: 10px 0;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .app .container {
    width: auto; /* Две колонки на средних экранах */
  }
}

.portfolio{
  padding: 40px 0;
}
.favicon{
scale: 3;
}