.main {
    position: relative;
    width: 100%;
    overflow: hidden;
}

/* .main::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at 50% 100%, rgba(0, 0, 0, 0.2) 43%, transparent 20%), radial-gradient(circle at 50% 0%, rgba(0, 0, 0, 0.2) 100%, transparent 20%);
    background-position: 0 0, 25px 25px;
    background-size: 50px 50px;
    opacity: 0.3;
} */
/* .main::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../public/img/background.jpg'); 
    background-size: contain;
    background-position: center; 
    opacity: 0.8; 
} */


.mrg-top{
    margin-top: 150px;
}