
.click_container {
  padding: 50px 0;
    position: relative;
    background-size: cover;
    background-position: center;
    min-height: 911px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* предотвратить выход псевдоэлемента за границы контейнера */
}

.click_container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.382), rgba(0, 0, 0, 0.461)), url('../../../public/img/Click.jpg');
    background-size: cover;
    background-position: center;
    filter: blur(2px); /* регулируйте значение для нужного уровня размытия */
    z-index: 0; /* отправляет фон за контент */
    transform: scale(1.1);
}

.click_btn-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 1;
}
.click_title {
    font-size: 50px;
    text-shadow: 12px -4px 2px rgb(0 0 0 / 30%);
}

.click_text {
    font-size: 50px;

  }
  .click_text span:first-child {
    /* любые нужные стили, чтобы текст был зафиксирован, например */
    position: relative; /* оставляет текст зафиксированным на месте */
  }
  
  .typing_effect {
    display: inline-block;
    width: 1100px; /* Задайте ширину, подходящую для самого длинного текста */
    white-space: nowrap; /* Отключаем перенос текста */
    text-align: left; /* Выравнивание по левому краю */

    color: var(--color-blue-elements);
  }

  .typing_effect::after {
    content: "|";
    margin-left: -14px;
    opacity: 1;
font-weight: 100;
    animation: blink 1.2s infinite;
  }
  
  @keyframes blink {
    0%, 50% {
      opacity: 1;
    }
    50%, 100% {
      opacity: 0;
    }
  }

  .click_btn_sec-container{
    width: 100%;
    display: flex;
    justify-content: center;
  }
.click-btn {
    position: relative;
    padding: 20px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    text-decoration: none;
    margin: 40px;
    overflow: hidden;
    transition: 1s;
    cursor: pointer;
}

.click-btn:hover {
    background: var(--clr);
    box-shadow: 0 0 10px var(--clr),
                0 0 30px var(--clr),
                0 0 60px var(--clr),
                0 0 100px var(--clr);
}

.click-btn::before {
    content: "";
    position: absolute;
    width: 40px;
    height: 775%;
    background: var(--clr);
    transition: 1s;
    animation: animate 6s linear infinite;
    animation-delay: calc(0.33s * var(--i));
}

.click-btn:hover::before {
    width: 120%;
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.click-btn::after {
    content: "";
    position: absolute;
    background:  var(--color-blue-elements);
    inset: 4px;
    transition: 0.5s;
}

.click-btn:hover::after {
    background: var(--clr);
}

.click-btn span {
    position: relative;
    z-index: 1;
    font-size: 1.5em; /* Увеличьте, если нужно */
    color: #fff;
    font-size: 30px;
    font-weight: 700;
    opacity: 0.8;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: 0.5s;
}

.click-btn:hover span {
    opacity: 1;
    color: rgba(47, 48, 58, 0.4); /* Цвет текста при наведении */
}


@media (max-width: 768px) {
  .click_btn-container{
    align-items: center;

  }
  .click_title{
    font-size: 40px;
  }
  .click_text{
    font-size: 20px;
    display: flex;
    gap: 5px;
    height: 30px;
  }
  .typing_effect{
      width: 291px;
      white-space: normal;
  }
  .typing_effect::after {
    margin-left: -5px;

  }
}