/* Основные стили для header */
.header {
  height: 160px;
  background-color: #2e2e2e;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: top 0.3s ease, height 0.3s ease;
}

.header.menu-open {
  height: 100vh;
}

.header .container {
  display: flex;
  justify-content: center;
}

.header-brand {
  font-size: 24px;
  font-weight: bold;
  margin: 0 auto;
}

.header_nav_list {
  display: flex;
  gap: 40px;
  padding-top: 10px;
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 1;
  transform: translateY(0);
}

.header_nav_item {
  cursor: pointer;
}

.svg-wrapper {
  position: relative;

  margin: 0 auto;
  width: 320px;
}

.shape {
  stroke-dasharray: 140 540;
  stroke-dashoffset: -474;
  stroke-width: 8px;
  fill: transparent;
  stroke: var(--color-blue-elements);
  transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s;
}

.svg-text {
  font-family: 'Roboto Condensed';
  font-size: 22px;
  letter-spacing: 8px;
  fill: #fff;
  pointer-events: none;
}

/* Hover и активное состояние для SVG элементов */
.svg-content:hover .shape {
  stroke-width: 15px;
}

.svg-content.active .shape {
  stroke-width: 3px;
  stroke-dashoffset: 0;
  stroke-dasharray: 760;
}

/* Стили для бургер-иконки */
.burger-icon {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 20px;
  width: 30px;
  height: 20px;
  z-index: 10;
}

.burger-icon .line {
  display: block;
  width: 100%;
  height: 2px;
  margin: 5px 0;
  background-color: var(--color-blue-elements);
  transition: transform 0.4s, opacity 0.4s;
}

/* Анимация для превращения бургер-меню в крестик */
.burger-icon .line1 {
  transform-origin: center;
  transition: transform 0.4s ease;
}

.burger-icon .line2 {
  opacity: 1;
  transition: opacity 0.4s ease;
}

.burger-icon .line3 {
  transform-origin: center;
  transition: transform 0.4s ease;
}

/* Меню в виде крестика */
.menu-open .burger-icon .line1 {
  transform: rotate(45deg) translate(5px, 5px);
}

.menu-open .burger-icon .line2 {
  opacity: 0;
}

.menu-open .burger-icon .line3 {
  transform: rotate(-45deg) translate(5px, -5px);
}

@media (max-width: 350px) {
  .header{
    min-height: 188px;
    
  }
  .burger-icon{
    right: 7px;
  }
}
@media (min-width: 769px) {
  .active-item-display{
    display: none;
  }
}
/* Стили для мобильного меню */
@media (max-width: 768px) {
  
  .container {
    position: relative;
  }

  .burger-icon {
    display: block;
  }

  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    overflow-y: hidden;
    z-index: 9;
  }
  .header .container {
height: 81vh;
  }
  .header.menu-open {
    transform: translateY(0);
    height: 100vh;
    z-index: 10000;
  }

  .header-brand {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .header_nav_list {
    flex-direction: column;
    opacity: 0;
    transform: translateY(-20px);
  }

  /* Анимация для появления меню */
  .header_nav_list.open {
    opacity: 1;
    transform: translateY(0);
  }
}
.active-item-display{
  position: absolute;
  top: 10px;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px); /* Легкий сдвиг вверх */
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.svg-text.fade-in {
  animation: fadeIn 0.5s ease;
}